.search {
  div.autocomplete {
    .autocomplete-input {
      input {
        text-align: left;
        padding: 0 0 1rem 1rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.38);
        width: 95%;
      }
    }
  }

  .admin-access {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    height: 100px;

    .switch-field {
      width: 50%;
      display: flex;
      justify-content: space-evenly;
    }

    .select-input {
      width: 50%;
    }
  }

  .form-error {
    padding: 0;
    text-align: left;
    margin-top: 0;
    margin-bottom: 2rem;
  }

  .filters {
    display: flex;
    align-items: flex-start;
    margin-top: 3rem;
    justify-content: space-between;

    .filter-state {
      display: flex;
      align-items: center;
      width: 100%;

      .select-status {
        width: 25%;
      }
    }

    #account-filter-label {
      margin: 0 1rem 0 2rem;
    }

    div {
      .container_search {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: rgba(#ffb81d, 0.2);
        width: 100%;

        .MuiTextField-root {
          border-radius: 5px;
          margin: 10px;

          input {
            padding: 6px 19px 7px;
          }
        }

        .mdi {
          font-size: 2rem;
          margin: 0.5rem;
        }
      }
    }
  }

  width: auto;

  .device-info {
    display: flex;
    justify-content: space-between;

    .version-logo {
      display: flex;
      align-items: center;
      width: 50%;
      margin-right: 0;
      gap: 30px;
    }
    .contact-user {
      width: 50%;
      margin-left: 2rem;
      .container_phone {
        margin-left: 0;
        display: flex;
        align-items: center;
        .container_phone_buttons {
          margin-left: 1rem;
        }
        .phone_input_component {
          .react-tel-input {
            width: 50%;
            .form-control {
              width: 50%;
              padding-left: 50px;
            }
          }
        }
      }
    }
    @media (max-width: 768px) {
      .contact-user {
        .container_phone {
          display: block;
          .container_phone_buttons {
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .MuiInputBase-fullWidth {
    padding:  5px 0px 0px 0px;
    margin-top:40px;
  }

  .MuiFormControl-root {
    margin-bottom: 1rem;
  }

  .modal {
    .form {
      margin: 0;

      .span-title {
        font-weight: bold;
      }

      .accounts_list {
        display: flex;
      }
    }

    .accounts_list {
      margin-top: 50px;
    }

    .MuiTableContainer-root {
      font-family: sans-serif;
      margin-bottom: 50px;
    }

    .info-app {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .MuiTextField-root {
        justify-content: flex-start;
      }
    }
  }
  .title-accounts {
    margin-top: 2rem;
    color: rgba(0, 0, 0, 0.38);
  }

  .css-bc21mx-MuiGrid-root > .MuiGrid-item {
    padding-top: 0;
  }

  .title-organization {
    color: rgba(0, 0, 0, 0.38);
    padding-top: 0;
    margin-top: 0;
  }
}

.container_buttons-principal {
  cursor: pointer;
}

.icon-create {
  top: -70px !important;
}

.newUser-appText {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container {
  margin-top: 20px;
  .list_table {
    display: block;
    margin-top: 2%;
    .check_boxes {
      width: 10%;

      .MuiCheckbox-root {
        width: 73px;
      }
    }
    .textItem {
      width: 100%;
      margin: auto;
      padding: 5px;
      .encabezado {
        text-align: center;
        font-weight: bold;
      }
      .adicional {
        text-align: center;
        font-weight: 100;
      }
    }
    .count {
      margin: 0.5rem;
      margin-left: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .accounts_list {
      margin-top: 0.938rem;
    }

    .list-field-items {
      ul {
        margin: 0;
        padding: 0;
      }

      li {
        width: 100%;
        border-bottom: 1px solid #ebecf1;
        list-style: none;
        cursor: pointer;
        display: flex;
        padding-top: 0em;
        i {
          margin-right: 0em;
          color: grey;
        }
      }
    }
    margin-bottom: 30px;
  }
  .MuiFormHelperText-root {
    font-size: small;
  }
  .li_default {
    background-color: #63ccca;
  }
}

.container-Expor {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  //padding: 0 1.5rem;
  margin-top: 15px;
}
.selectContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.label {
  margin-bottom: 8px;
}
.select {
  width: 100%;
  margin-bottom: 8px;
}

.container-forms {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

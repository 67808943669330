.card {
  width: 12rem;
  height: 23rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  background-color: #fff;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: scale(1.03);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.card-media {
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  width: 100%;
}

.card-media .card-icon {
  font-size: 48px;
  color: #555;
}

.card-media .card-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.card-content {
  height: 35%;
  padding: 16px;
  text-align: center;
  width: 90%;
}

.tags-container {
  margin: 0.8rem;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.chip {
  display: inline-block;
  background-color: #e0e0e0;
  border-radius: 16px;
  padding: 4px 8px;
  font-size: 0.8rem;
  color: #333;
}

.chip.secondary {
  background-color: #ffb81d;
  color: #000;
}

.card-content .title {
  font-size: 0.8rem;
  font-weight: bold;
}

.card-actions {
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  width: 100%;
}

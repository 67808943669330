@import "~@mdi/font/scss/materialdesignicons";
@import "../../core/components/SideMenu/AppSideMenu";

.dashboard {
  display: flex;
  height: 100vh;
  position: relative;
  overflow: hidden;
  .dashboard-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
  }
  .container_buttons-principal {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .buttons-principal {
      position: absolute;
      top: -101px;
      display: flex;
      width: 85%;
      justify-content: flex-end;
    }

    .container-icon {
      display: flex;
      align-items: center;
    }

    .mdi {
      font-size: 1.25rem;
      margin-left: 20px;
      margin-right: 7px;
    }
  }
}

.header {
  background-color: #ffb81d;
  min-height: 80px;
  font-size: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: -2;

  .container-title {
    width: 90%;
    margin: 0 auto;
  }

  .title {
    display: flex;
    align-items: center;
  }
}

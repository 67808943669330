.images {
  padding: 2rem 0rem;
  height: 90%;

  .images-storage {
    display: flex;
    flex-wrap: wrap;

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 13rem;
      width: 10rem;
      border: 3px solid black;
      border-radius: 1rem;
      margin: 1rem 2rem;
      white-space: nowrap;
      overflow: hidden;

      h4 {
        margin-top: 2px;
        white-space: initial;
        width: 80%;
      }
      i {
        font-size: 7rem;
        color: black;
      }
    }
  }

  .container_header {
    height: 3rem;
    .container_search {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: rgba(#ffb81d, 0.2);
      width: 100%;
      .MuiInputBase-root {
        width: 80%;
        border-radius: 5px;
        input {
          padding: 6px 19px 7px;
        }
      }
      .mdi {
        font-size: 2rem;
        margin: 0.5rem;
      }
    }
  }
}
.loading {
  margin: auto;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  .MuiCircularProgress-svg {
    color: #ffb81d;
  }
}
.loading {
  margin-top: 5rem;
}

.btnContainers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.login {
  display: flex;
  justify-content: center;
  .logo {
    margin: 4rem 0 3rem;

    img {
      object-fit: contain;
      width: 16rem;
    }
    .admin-name {
      letter-spacing: 2px;
    }
  }

  .container {
    text-align: center;
    width: 40%;
    padding: 0 1.5rem;
    max-width: 420px;

    .login-form {
      display: flex;
      flex-direction: column;

      &__ .btn-email {
        background: #ffb81d;
        color: black;
        &:hover {
          background: #dc961d;
        }
      }
      .btn-google {
        margin: 20px 0 0 0;
        background: #dd4b39;
        color: white;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        font-weight: 500;
        line-height: 1.75;
        font-size: 0.875rem;
        letter-spacing: 0.02857em;
        text-transform: uppercase;
        &:hover {
          background: #f44336;
        }
      }
    }

    @media only screen and (max-width: 600px) {
      background-color: pink;
      width: 80%;
      margin: 0 auto;
      max-width: 420px;
    }
    .select-type-mfa {
      height: 350px;

      button {
        margin: 70px 0 0 0;
        background: #ffb81d;
        &:hover {
          background: #dc961d;
        }
      }
    }

    .return {
      margin: 10px 0 0 0;
      color: #ffb81d;
      cursor: pointer;
    }

    .informative-text_mfa {
      margin: 50px 0 0 0;
    }

    .select-type-mfa {
      display: flex;
      flex-direction: column;
      align-items: center;

      .code-verification {
        display: flex;
        flex-direction: column;
        align-items: center;

        .MuiFormControl-root.MuiTextField-root.texto {
          margin: 15px 0 0 0;
        }

        .resend_code {
          margin: 80px 0 0 0;
          a {
            color: #ffb81d;
            cursor: pointer;
          }
        }

        input#codeNumber {
          text-align: center;
        }
      }
    }

    .form-error {
      margin-bottom: 0.5rem;
    }

    .Errormessage {
      color: #f44336;
      border-radius: 2px;
      border: 1px solid #f44336;
      background-color: rgba(#f44336, 0.2);
      padding: 0.5rem 1rem;
      text-align: center;
      margin-top: 1rem;
      margin-bottom: -1rem;
      font-size: rem-calc(14);
    }

    .container_mfa {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 420px;
      padding: 0 25px;
      height: 400px;

      form {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      input#codeNumber {
        text-align: center;
      }

      button {
        margin: 70px 0 0 0;
      }

      .legend_mfa {
        text-align: center;
      }

      .phone-input {
        width: 100%;
        padding: 0 0 0 35px;
        background-color: #f8f9fa;
      }

      .message-remove-mfa {
        text-align: justify;
        line-height: 23px;
      }

      .informative-text_mfa {
        text-align: justify;
      }

      .send-email {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      .return {
        margin: 10px 0 0 0;
      }

      .select-type-mfa {
        display: flex;
        flex-direction: column;
        align-items: center;

        .code-verification {
          display: flex;
          flex-direction: column;
          align-items: center;

          .MuiFormControl-root.MuiTextField-root.texto {
            margin: 15px 0 0 0;
          }

          .resend_code {
            margin: 80px 0 0 0;
          }
        }
      }
    }
  }
}

.container-textFields {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

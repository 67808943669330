.container-btns {
  display: flex;
  justify-content: center;
  gap: 10px;

  .__back {
    width: 100%;
  }
}
.container-headers {
  height: 3rem;
  flex-grow: 1;
  margin-top: 20px;
}

.container-selec {
  margin-top: 2px;
}
.container-selec-for {
  margin-top: 10px;
  margin-bottom: 10px;
}


.container-switch {
margin-bottom: 20px;
display: flexbox;
align-items: flex-start;
}

.container-inp {
  margin-top: 5px;
}

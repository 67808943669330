div.multi_list {
  margin-bottom: 0.5rem;

  div.react-select {
    &__menu {
      margin-top: 2px;
    }

    &__control {
      border: 1px solid grey;
      border-radius: 3px;
      box-shadow: none;
      height: 100%;
      padding: 10px;
      text-align: initial;
    }

    &__control--is-focused {
      border-bottom: 2px solid #ffb81d;
    }

    &__indicators {
      .react-select__indicator-separator {
        display: none;
      }
      .react-select__dropdown-indicator {
        display: none;
      }
    }
    .react-select__placeholder {
      display: none;
    }
  }
}
.cont-lab {
  margin-bottom: 15px;
  top: -40px !important;
  left: -13px !important;
}

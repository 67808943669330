.phone_component {
  display:flex;
  .phone_input_component {
    width: 65%;
  }
  .phone_input_component {
    .form-control {
      padding-left: 30px;
    }
  }
  .phone-buttons {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 5px;

    .MuiButton-root {
      .MuiButton-startIcon {
        margin: 0;
      }
    }
  }
}

.test {
  background-color: black;
}

div.autocomplete {
    margin-bottom: 0.3rem;
    position: relative;
    display: flex;
    align-items: center;
  
    .autocomplete-input {
      width: 100%;
      position: relative;
      margin-right: 8px;
  
      input {
        border: 0;
        border-bottom: 1px solid #000;
        color: black;
        text-align: left;
        width: 100%;
        padding: 8px 0 0px 4px;
        outline: none;
        font-size: 1rem;
  
        &:focus {
          border-bottom-color: orange;
          border-bottom-width: 1.5px;
        }
  
        &:disabled {
          background-color: white;
          color: rgba(0, 0, 0, 0.38);
          border-bottom-color: rgba(0, 0, 0, 0.2);
        }
      }
  
      i.mdi-chevron-down {
        font-size: 22px;
        top: 4px;
        right: 0;
        position: absolute;
      }
  
      .MuiCircularProgress-root {
        position: absolute;
        right: 8px;
        top: 6px;
      }
    }
  
    .autocomplete-dropdown {
      background-color: #fff;
      position: absolute;
      top: 36px;
      box-shadow: 0 2px 4px rgba(grey, 0.7);
      left: 0;
      right: 0;
      max-height: 240px;
      overflow-y: auto;
      z-index: 10;
  
      ul {
        margin: 0;
        padding: 4px 0;
      }
  
      li {
        list-style: none;
        padding: 10px 16px;
        cursor: pointer;
      }

      li:hover{
        background-color: #e8e8e8;
      }
  
      .create-data {
        background-color: turquoise;
        cursor: pointer;
        font-weight: 500;
        box-shadow: 0 2px 8px rgba(grey, 0.6);
        padding: 8px 12px;
        z-index: 10;
      }
    }
  
    .mdi-close {
      font-size: 16px;
      margin-top: -4px;
    }
  
    i {
      cursor: pointer;
    }
  }
  